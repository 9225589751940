@use "sass:map";
@use "sass:math";
@use "./modules/layout-definitions";
@use "./modules/tile-layout";

.sync-page-container {
  display: block;
  box-sizing: border-box;
  width: 100%;
  max-width: math.max(map.values(layout-definitions.$device-width) ...);
  margin-left: auto;
  margin-right: auto;

  // Set the default padding using the 'base' inset value
  padding: 0 map.get(layout-definitions.$page-container-inset, "base");

  .row {
    margin-inline: 0;
  }

  // Generate media queries for the remaining inset values
  @each $key,
    $value in map.remove(layout-definitions.$page-container-inset, "base")
  {
    @media (min-width: map.get(layout-definitions.$device-width, $key)) {
      padding: 0 map.get(layout-definitions.$page-container-inset, $key);
    }
  }
}

.sync-page-container {
  .extend-to-container-edge {
    margin-left: 0 - map.get(layout-definitions.$page-container-inset, "base");
    margin-right: 0 - map.get(layout-definitions.$page-container-inset, "base");
    @each $key,
      $value in map.remove(layout-definitions.$page-container-inset, "base")
    {
      @media (min-width: map.get(layout-definitions.$device-width, $key)) {
        margin-left: 0 - map.get(layout-definitions.$page-container-inset, $key);
        margin-right: 0 -
          map.get(layout-definitions.$page-container-inset, $key);
      }
    }
  }
}

/*
Mixins to create media query blocks using semantic device names
*/
@mixin min-width($size) {
  $breakpoints: map.remove(layout-definitions.$device-width, "base");
  @if not map.has-key($breakpoints, $size) {
    @error "`#{$size}` is not a valid breakpoint name. Please use one of the following: `#{map-keys($media-query-breakpoints)}`'";
  } @else {
    @media (min-width: map.get($breakpoints, $size)) {
      @content;
    }
  }
}

@mixin max-width($size) {
  $breakpoints: map.remove(layout-definitions.$device-width, "base");
  @if not map.has-key($breakpoints, $size) {
    @error "`#{$size}` is not a valid breakpoint name. Please use one of the following: `#{map-keys($media-query-breakpoints)}`'";
  } @else {
    @media (max-width: map.get($breakpoints, $size) - 1) {
      @content;
    }
  }
}

@mixin min-max-width($sizeMin, $sizeMax) {
  $breakpoints: map.remove(layout-definitions.$device-width, "base");
  @if not map.has-key($breakpoints, $sizeMin) {
    @error "`#{$sizeMin}` is not a valid breakpoint name for min-width. Please use one of the following: `#{map-keys($media-query-breakpoints)}`'";
  } @else if not map.has-key($breakpoints, $sizeMax) {
    @error "`#{$sizeMax}` is not a valid breakpoint name for max-width. Please use one of the following: `#{map-keys($media-query-breakpoints)}`'";
  } @else {
    @media (min-width: map.get($breakpoints, $sizeMin)) and (max-width: map.get($breakpoints, $sizeMax)) {
      @content;
    }
  }
}

.sync-grid {
  margin-bottom: map.get(layout-definitions.$grid-spacing-stack, "base");
  @each $key,
    $value in map.remove(layout-definitions.$grid-spacing-stack, "base")
  {
    @media (min-width: map.get(layout-definitions.$device-width, $key)) {
      margin-bottom: map.get(layout-definitions.$grid-spacing-stack, $key);
    }
  }
  @include tile-layout.generate-grid(
    layout-definitions.$device-width,
    layout-definitions.$grid-columns-feature,
    layout-definitions.$grid-rows,
    layout-definitions.$grid-spacing-inline,
    layout-definitions.$grid-spacing-stack
  );

  &:empty {
    display: none;
  }
}
