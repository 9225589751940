@use "sass:string";
@use "../../../../sass/utilities" as common;

$_icon-size: 13px;
$_icon-spacing: 4px;

:host {
  @include common.shadow-dom-reset;
  display: block;
}

// Layout and typography
.ds-semantic-message {
  margin: 0;
  @include common.font-style("label", "medium");
  padding-left: $_icon-size + $_icon-spacing;
  .ds-purpose {
    @include common.a11y-sr-only;
  }
}

// Icons
.ds-semantic-message {
  background-repeat: no-repeat;
  background-size: $_icon-size $_icon-size;
  background-position: left top;
  &,
  &.ds-purpose-attention {
    color: common.color("attention", "dark-2");
    background-image: common.get-icon(
      "fa-solid-circle-info",
      common.color("attention", "dark-2")
    );
  }
  &.ds-purpose-success {
    color: common.color("success", "dark-2");
    background-image: common.get-icon(
      "fa-solid-circle-check",
      common.color("success", "dark-2")
    );
  }
  &.ds-purpose-warning {
    color: common.color("warning", "dark-2");
    background-image: common.get-icon(
      "fa-solid-triangle-exclamation",
      common.color("warning", "dark-2")
    );
  }
  &.ds-purpose-error {
    color: common.color("error", "dark-2");
    background-image: common.get-icon(
      "fa-solid-circle-xmark",
      common.color("error", "dark-2")
    );
  }
}
