@use "sass:math";
@use "../../../sass/utilities" as common;

@mixin responsive-size($min, $max, $scale) {
  font-size: clamp(#{$min}, calc(0.5rem + #{$scale}), #{$max});
  @if math.unit($min) != "rem" {
    @error "`#{$min}` must be a rem value";
  }
  @if math.unit($max) != "rem" {
    @error "`#{$max}` must be a rem value";
  }
}

@mixin code-like {
  font-family: monospace;
  @include common.font-style("body", "base");
  line-height: common.line-height("small");
  background-color: common.color("grey", "light-2");
  padding: 0.2em 0.4em;
}
