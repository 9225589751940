@use "sass:map";
@use "../../variables/breakpoint-settings" as bp;

@mixin min-breakpoint($device) {
  @if map.has-key(bp.$breakpoints, $device) {
    @media (min-width: map-get(bp.$breakpoints, $device)) {
      @content;
    }
  } @else {
    @error "#{$device} is not a valid device name. Please use one of the following: #{map-keys(bp.$breakpoints)}";
  }
}

@mixin max-breakpoint($device) {
  @if map.has-key(bp.$breakpoints, $device) {
    @media (max-width: calc(#{map-get(bp.$breakpoints, $device)} - 1px)) {
      @content;
    }
  } @else {
    @error "#{$device} is not a valid device name. Please use one of the following: #{map-keys(bp.$breakpoints)}";
  }
}

@mixin min-max-breakpoint($deviceMin, $deviceMax) {
  @if not map.has-key(bp.$breakpoints, $deviceMin) {
    @error "`#{$deviceMin}` is not a valid breakpoint name for min-width. Please use one of the following: `#{map-keys(bp.$breakpoints)}`'";
  } @else if not map.has-key(bp.$breakpoints, $deviceMax) {
    @error "`#{$deviceMax}` is not a valid breakpoint name for max-width. Please use one of the following: `#{map-keys(bp.$breakpoints)}`'";
  } @else {
    @media (min-width: map.get(bp.$breakpoints, $deviceMin)) and (max-width: map.get(bp.$breakpoints, $deviceMax)) {
      @content;
    }
  }
}
