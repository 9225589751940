@use "../../../../sass/utilities" as common;

.ds-layout-grid {
  $min-col-width: 300px; // Need to refine this value. Variations?
  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: 1fr; // Prevent overflow on narrow devices

  @include common.min-breakpoint("phone-regular") {
    grid-template-columns: repeat(auto-fit, minmax(#{$min-col-width}, 1fr));
  }
}

// Column variants
.ds-layout-grid {
  &.ds-layout-grid-2col {
    grid-template-columns: 1fr;

    @include common.min-breakpoint("phablet") {
      grid-template-columns: repeat(auto-fit, minmax(calc(50% - 0.5rem), 1fr));
    }
  }
}

// Grid dividers for 2 column pattern
.ds-layout-grid-2col {
  // Grid column dividers
  &.ds-layout-grid-dividerY,
  &.ds-layout-grid-dividerX {
    @include common.min-breakpoint("phablet") {
      > * {
        position: relative; /* Parent must be positioned for aboslute positioning of pseudo-elements to work */
        overflow: visible; /* overflow: hidden will hide borders */
        z-index: 1;
      }
    }
  }

  &.ds-layout-grid-dividerY {
    > :nth-child(n)::before,
    > :nth-child(n)::after {
      z-index: -1;
    }

    /* Vertical lines to the left of cells in the top row */
    > :nth-child(2n)::before {
      content: "";
      position: absolute;
      top: 0.5rem;
      left: 0;
      height: calc(100% - 1rem);
      border-left: 1px solid rgba(0 0 0 / 10%);
    }

    /* Horizontal lines above cells in the first column */
    > :nth-child(2n + 3)::after {
      content: "";
      position: absolute;
      top: -0.5rem;
      left: 0.5rem;
      width: calc(100% - 1rem);
      border-top: 1px solid rgba(0 0 0 / 10%);
    }

    /* Horizontal lines above cells in all other columns */
    > :nth-child(2n + 4)::after {
      content: "";
      position: absolute;
      top: -0.5rem;
      left: 0.5rem;
      width: calc(100% - 1rem);
      border-top: 1px solid rgba(0 0 0 / 10%);
    }
  }

  &.ds-layout-grid-dividerX {
    > :nth-child(n)::before {
      z-index: -1;
    }

    /* Vertical lines to the left of cells in all other rows */
    > :nth-child(2n + 2)::before {
      content: "";
      position: absolute;
      top: 0.5rem;
      left: -0.5rem;
      height: calc(100% - 1rem);
      border-left: 1px solid rgba(0 0 0 / 10%);
    }
  }
}
