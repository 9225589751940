@use "../../../../sass/utilities" as common;

kbd {
  font-family: monospace;
  @include common.font-style("label", "medium");
  background-color: common.color("grey", "light-2");
  border: 1px solid common.color("grey", "dark-1");
  border-radius: common.border-radius(4);
  padding: 0.2em 0.4em;
}
