// imports moved from angular config
@use "./app/shared/scss/sync-grid.scss" as *;
@use "@esolutions/design-system-ng/sass/global-styles";

// Cut down version of the Angular material styles
@use "./app/scss/material/material" as *;

@import "../node_modules/@fortawesome/fontawesome-pro/css/all.min.css";

body {
  margin: 0;
  word-break: break-word;

  app-root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  app-home {
    flex: 1 0 auto;
    min-width: 0;
  }

  &.has-overlay-megamenu,
  &.has-overlay-video {
    overflow: hidden;
  }

  .ds-a11y-visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    left: -10000px;
  }
}

.ds-cta {
  &:hover, &:focus {
    text-decoration: none !important;
  }
}

.tracking-link, .tracking-link:hover, .tracking-link:focus {
  text-decoration: none !important;
}

