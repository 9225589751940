@use "../../shared/scss/sync-grid.scss" as sync-grid;
@use "@esolutions/design-system-ng/sass/utilities" as ds;
@use "sass:map";
@use "overlay";

$close-button: 2.5rem;
$close-button-ring: 1.5rem;

// Styles from Angular material with variable overrides.
@include overlay.cdk-overlay;

$overlay-pane-inset: (
  "regular-phone": ds.spacing("layout", "minus-1"),
  "phablet": ds.spacing("layout", "plus-2")
);

// Further style overides to make the dialog responsive
.cdk-overlay-pane {
  width: 100%;

  @include sync-grid.min-width("regular-phone") {
    width: calc(100vw - (2 * #{map.get($overlay-pane-inset, "regular-phone")}));
  }

  @include sync-grid.min-width("phablet") {
    width: calc(100vw - (2 * #{map.get($overlay-pane-inset, "phablet")}));
  }

  @include sync-grid.min-width("portrait-tablet") {
    width: 528px;
  }

  @include sync-grid.max-width("regular-phone") {
    height: 100%;
  }
}

// Needs this combination selector to increase specificity above the
// built in styles.
mat-dialog-container.mat-mdc-dialog-container {
  background-color: ds.color("white");
  border-radius: 0;
  overflow: auto;

  .wrapper {
    margin: ds.spacing("layout", "plus-1") ds.spacing("layout", "plus-1") (ds.spacing("layout") * 1.75);
  }

  scrollbar-width: thin;

  h2 {
    margin-top: 0;
    margin-right: 3rem;
  }

  .close-button {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $close-button;
    height: $close-button;
    top: -#{ds.spacing("layout", "minus-2")};
    right: -#{ds.spacing("layout", "minus-2")};

    &:before {
      content: "";
      z-index: -1;
      width: $close-button-ring;
      height: $close-button-ring;
      position: absolute;
      top: calc(50% - #{$close-button-ring * 0.5});
      border-radius: $close-button-ring;
      border: 1px solid transparent;
    }

    span {
      font-size: 1rem;
      margin: 2px 0 0;
    }

    @media screen and (hover: hover) {
      &:hover {
        &:before {
          border-color: ds.color("charcoal", "base");
        }

        span {
          color: ds.color("charcoal", "dark-1");
        }
      }
    }

    &:active {
      &:before {
        border-color: ds.color("charcoal", "base");
        background-color: ds.color("charcoal", "base");
      }

      span {
        color: ds.color("white");
      }
    }
  }

  @include sync-grid.min-width("regular-phone") {
    border-radius: 4px;
    box-shadow: ds.box-shadow(8);
    margin: auto;
    margin-bottom: ds.spacing("layout", "plus-1");
    max-height: 80vh;
    max-width: 90vw;
  }
}
