@use "../../../../sass/utilities" as common;
@use "../../../../sass/variables/form-settings" as form;

@mixin text-like {
  &:is(input) {
    &:is([type="text"], [type="number"], [type="tel"], [type="email"], [type="password"], [type="url"]) {
      @content;
    }
  }
}

// Dimensions and typography
.ds-form-input {
  @include text-like {
    display: block;
    margin: common.spacing("layout", "minus-2") 0;
    width: 100%;
    height: form.$form-input-min-height;
    padding: 0 common.spacing("layout", "base") * 3 0
      common.spacing("layout", "base");
    @include common.font-style("heading", "h6");
    font-weight: common.font-weight("base");
  }
}

// Border
.ds-form-input {
  @include text-like {
    border: 1px solid common.color("grey", "base");
    border-radius: common.border-radius(4);
    &:hover {
      border-color: common.color("charcoal", "light-1");
    }
    &:focus {
      outline: 3px solid common.color("blue", "light-2");
      outline-offset: 2px;
    }
    &[data-touched="true"]:invalid,
    &.ng-touched.ng-invalid {
      border: 2px solid common.color("error", "base");
      &:hover {
        border-color: common.color("error", "dark-2");
      }
    }
  }
}

// Icons
.ds-form-input {
  @include text-like {
    &[data-touched="true"]:invalid,
    &.ng-touched.ng-invalid,
    &[data-dirty="true"]:valid,
    &.ng-dirty.ng-valid {
      background-repeat: no-repeat;
      background-size: common.spacing("layout", "base")
        common.spacing("layout", "base");
      background-position: right common.spacing("layout", "base") center;
    }
    &[data-touched="true"]:invalid,
    &.ng-touched.ng-invalid {
      background-image: common.get-icon(
        "fa-solid-circle-exclamation",
        common.color("error", "dark-2")
      );
    }
    &[data-dirty="true"]:valid,
    &.ng-dirty.ng-valid {
      background-image: common.get-icon(
        "fa-solid-circle-check",
        common.color("success", "dark-2")
      );
    }
  }
}

// Supportive text
.ds-form-input {
  @include text-like {
    & + .ds-form-supportive-text {
      color: common.color("charcoal", "light-2");
      margin: common.spacing("type", "minus-2") 0;
      @include common.font-style("label", "medium");
    }
    &[data-touched="true"]:invalid,
    &.ng-touched.ng-invalid {
      & + .ds-form-supportive-text {
        color: common.color("error", "dark-2");
      }
    }
  }
}

// Disabled and read-only state
.ds-form-input {
  @include text-like {
    &:disabled,
    &:read-only {
      border-color: common.color("grey", "base");
      background-color: common.color("grey", "light-2");
      color: common.color("grey", "base");
      cursor: not-allowed;
      & + .ds-form-supportive-text {
        color: common.color("grey", "base");
      }
    }
  }
}
