@use "sass:math";
@use "sass:map";
@use "../../../../sass/utilities" as common;
@use "../../shared";

$_lead-text-levels: (
  "h1": "h2",
  "h2": "h3",
  "h3": "h4",
  "h4": "h5",
  "h5": "h6",
  "h6": "h6",
);

@mixin _lead($level) {
  display: block;
  color: common.color("text", "light");
  @include common.font-style("heading", $level);
  font-weight: common.font-weight("base");
}

@each $i, $value in $_lead-text-levels {
  #{$i} + .ds-lead {
    @include _lead($value);
  }

  .ds-#{$i} + .ds-lead {
    @include _lead($value);

    &:first-child {
      margin-top: 0;
    }
  }
}
