@use "sass:map";
@use "sass:meta";
@use "../../../../sass/utilities" as common;
@use "../../../variables/breakpoint-settings" as bp;

$cols: 12;

:root {
  --grid-fluid-max: 100%;
  --grid-fixed-max: #{common.max-breakpoint("desktop")};

  --grid-gutter-xs: #{common.spacing("layout", "minus-2")};
  --grid-gutter-sm: #{common.spacing("layout", "minus-1")};
  --grid-gutter-md: #{common.spacing("layout", "base")};
  --grid-gutter-lg: #{common.spacing("layout", "plus-2")};

  --grid-margin-sm: #{common.spacing("layout", "minus-1")};
  --grid-margin-md: #{common.spacing("layout", "base")};
  --grid-margin-lg: #{common.spacing("layout", "plus-2")};
}

section {
  container-type: inline-size;
}

@mixin grid-bp($args...) {
  $defaultPadding: null;
  $defaultMargin: null;
  $defaultGutter: null;

  @each $name, $size in meta.keywords($args) {
    $sizeValue: $size;
    $padding: $defaultPadding;
    $margin: $defaultMargin;
    $gutter: $defaultGutter;

    @if type-of($size) == map {
      $sizeValue: map-get($size, "size");
      $padding: map-get($size, "padding") !default;
      $margin: map-get($size, "margin") !default;
      $gutter: map-get($size, "gutter") !default;
    }

    @media screen and (min-width: #{$sizeValue}) {
      @for $i from 1 through $cols {
        .ds-col-#{$i}-#{$name} {
          display: block;
          padding: 0;

          grid-column: span #{$i};
        }
      }
    }

    @media screen and (min-width: #{$sizeValue}) {
      margin-inline: $margin;
      padding-inline: $padding;

      @if $gutter {
        grid-gap: $gutter;
      }
    }
  }
}

.ds-layout-columns {
  &,
  &.ds-grid-fixed {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: auto;
    grid-gap: var(--grid-gutter-sm);
    width: auto;
    max-width: var(--grid-fixed-max);
    margin-inline: var(--grid-margin-md);

    .ds-col {
      display: block;
      padding: 0;

      grid-column: span $cols;
    }

    @include grid-bp(
      $xs: (
        "size": map.get(bp.$breakpoints, "phone-small"),
        "gutter": var(--grid-gutter-sm),
        "padding": var(--grid-margin-md),
        "margin": 0,
      ),
      $sm: (
        "size": map.get(bp.$breakpoints, "phone-regular"),
        "gutter": var(--grid-gutter-sm),
        "padding": var(--grid-margin-md),
        "margin": 0,
      ),
      $md: (
        "size": map.get(bp.$breakpoints, "phablet"),
        "gutter": var(--grid-gutter-md),
        "padding": var(--grid-margin-lg),
        "margin": 0,
      ),
      $lg: (
        "size": map.get(bp.$breakpoints, "tablet-portrait"),
        "gutter": var(--grid-gutter-md),
        "padding": var(--grid-margin-lg),
        "margin": 0,
      ),
      $xl: (
        "size": map.get(bp.$breakpoints, "tablet-landscape"),
        "gutter": var(--grid-gutter-lg),
        "padding": var(--grid-margin-lg),
        "margin": 0,
      ),
      $xxl: (
        "size": map.get(bp.$breakpoints, "desktop"),
        "gutter": var(--grid-gutter-lg),
        "padding": var(--grid-margin-lg),
        "margin": auto,
      )
    );

    .ds-layout-columns {
      margin-block: 0;
      margin-inline: 0;
      padding-inline: unset !important;
    }
  }
}

// Gutters - define the spaces bewteen objects
.ds-layout-columns {
  &.ds-grid-gutter-xs {
    grid-column-gap: calc(var(--grid-gutter-xs) * 1.5);
    grid-row-gap: calc(var(--grid-gutter-xs) * 1.25);

    @each $width, $value in bp.$breakpoints {
      @media screen and (max-width: $value) {
        @if $value <= map.get(bp.$breakpoints, "phablet") {
          column-gap: 0;
        }
      }
    }
  }

  &.ds-grid-gutter-sm {
    grid-column-gap: calc(var(--grid-gutter-sm) * 1.5);
    grid-row-gap: calc(var(--grid-gutter-sm) * 1.25);

    @each $width, $value in bp.$breakpoints {
      @media screen and (max-width: $value) {
        @if $value <= map.get(bp.$breakpoints, "phablet") {
          column-gap: 0;
        }
      }
    }
  }

  &.ds-grid-gutter-lg {
    grid-column-gap: calc(var(--grid-gutter-lg) * 1.5);
    grid-row-gap: calc(var(--grid-gutter-lg) * 1.25);

    @each $width, $value in bp.$breakpoints {
      @media screen and (max-width: $value) {
        @if $value <= map.get(bp.$breakpoints, "phablet") {
          column-gap: 0;
        }
      }
    }

    @each $width, $value in bp.$breakpoints {
      @media screen and (min-width: $value) {
        @if $value >= map.get(bp.$breakpoints, "tablet-portrait") {
          grid-gap: var(--grid-gutter-lg);
        }
      }
    }
  }
}

// Margins - define the space within the page
.ds-layout-columns {
  &.ds-grid-margin-sm {
    padding-inline: var(--grid-margin-sm);

    &.ds-grid-fluid {
      padding-inline: var(--grid-margin-sm);

      @each $width, $value in bp.$breakpoints {
        @media screen and (min-width: $value) {
          @if $value >= map.get(bp.$breakpoints, "desktop") {
            padding-inline: 0;
          }
        }
      }
    }
  }

  &.ds-grid-margin-lg {
    padding-inline: var(--grid-margin-lg);

    @each $width, $value in bp.$breakpoints {
      @media screen and (min-width: $value) {
        @if $value >= map.get(bp.$breakpoints, "phablet") {
          padding-inline: var(--grid-margin-lg);
        }
      }
    }
  }
}

// Fluid columns
.ds-layout-columns {
  &.ds-grid-fluid {
    max-width: calc(100% - (var(--grid-margin-lg) * 2));

    @include grid-bp(
      $xxl: (
        "size": map.get(bp.$breakpoints, "desktop"),
        "gutter": var(--grid-gutter-lg),
        "padding": var(--grid-margin-lg),
        "margin": var(--grid-margin-lg),
      )
    );
  }
}

// Forms - remove any unwanted spacing from form elements
form .ds-layout-columns {
  .ds-form-field,
  .ds-form-item-select {
    margin: 0;
  }
}

section {
  padding-block: 2rem;
}
