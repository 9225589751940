@use "../../../../sass/utilities" as common;
@use "../../../../sass/variables/form-settings" as form;

// Dimensions and typography
.ds-form-textarea {
  display: block;
  margin: common.spacing("layout", "minus-2") 0;
  width: 100%;
  min-height: form.$form-textarea-min-height;
  padding: common.spacing("layout", "minus-2") common.spacing("layout", "base") *
    3 common.spacing("layout", "minus-2") common.spacing("layout", "base");
  @include common.font-style("label", "large");
  font-weight: common.font-weight("base");
}

// Border
.ds-form-textarea {
  outline: none;
  border: 1px solid common.color("grey", "base");
  border-radius: common.border-radius(4);
  &:hover {
    border-color: common.color("charcoal", "light-1");
  }
  &:focus {
    outline: 3px solid common.color("blue", "light-2");
    outline-offset: 2px;
  }
  &[data-touched="true"]:invalid,
  &.ng-touched.ng-invalid {
    border: 2px solid common.color("error", "base");
    &:hover {
      border-color: common.color("error", "dark-2");
    }
  }
}

// Icons
.ds-form-textarea {
  &[data-touched="true"]:invalid,
  &.ng-touched.ng-invalid,
  &[data-dirty="true"]:valid,
  &.ng-dirty.ng-valid {
    background-repeat: no-repeat;
    background-size: common.spacing("layout", "base")
      common.spacing("layout", "base");
    background-position: right common.spacing("layout", "base") top
      calc(#{common.spacing("layout", "minus-2")} + 2px); // Add width of border
  }
  &[data-touched="true"]:invalid,
  &.ng-touched.ng-invalid {
    background-image: common.get-icon(
      "fa-solid-circle-exclamation",
      common.color("error", "dark-2")
    );
  }
  &[data-dirty="true"]:valid,
  &.ng-dirty.ng-valid {
    background-image: common.get-icon(
      "fa-solid-circle-check",
      common.color("success", "dark-2")
    );
  }
}

// Supportive text
.ds-form-textarea {
  & + .ds-form-supportive-text {
    margin: common.spacing("type", "minus-2") 0;
    @include common.font-style("label", "medium");
  }
  &[data-touched="true"]:invalid,
  &.ng-touched.ng-invalid {
    & + .ds-form-supportive-text {
      color: common.color("error", "dark-2");
    }
  }
}

// Disabled and read only state
.ds-form-textarea {
  &:disabled,
  &:read-only {
    border-color: common.color("grey", "base");
    background-color: common.color("grey", "light-2");
    color: common.color("grey", "base");
    cursor: not-allowed;

    & + .ds-form-supportive-text {
      color: common.color("grey", "base");
    }
  }
}
