@use "sass:math";
@use "sass:map";
@use "../../shared";
@use "../../../mixins/font-style/font-style" as *;
@use "../../../../sass/utilities" as common;

$_heading-levels: ("h1", "h2", "h3", "h4", "h5", "h6");

$_heading-levels-h1: ("hero", "h1--alt");
$_heading-levels-h2: ("hero", "h1--alt");

@mixin _headings($level) {
  display: block;
  @include font-style("heading", $level);
  font-weight: common.font-weight("bold");
  margin-block-start: common.spacing("type", "plus-1");
  margin-block-end: common.spacing("type", "minus-1");

  a {
    font-weight: inherit;
  }
}

@each $i in $_heading-levels {
  #{$i} {
    @include _headings($i);
  }

  .ds-#{$i} {
    @include _headings($i);

    &:first-child {
      margin-top: 0;
    }
  }
}

// h1 modifiers
:is(h1, .ds-h1) {
  @each $i in $_heading-levels-h1 {
    &.ds-#{$i} {
      @include font-style("heading", $i);
    }
  }
}

// h2 modifiers
:is(h2, .ds-h2) {
  @each $i in $_heading-levels-h2 {
    &.ds-#{$i} {
      @include font-style("heading", $i);
    }
  }
}

// Underline modifier
:is(h1, h2, h3, h4, h5, h6, .ds-h1, .ds-h2, .ds-h3, .ds-h4, .ds-h5, .ds-h6) {
  &.ds-border-bottom {
    padding-bottom: common.spacing("type", "minus-2");
  }
}

// Worldly styles
// This now introduces Worldly as a font family
:is(h1, h2, h3, .ds-hero, .ds-h1, .ds-h1--alt, .ds-h2, .ds-h3) {
  &.ds-worldly-regular {
    font-family: "Worldly Regular";
    font-weight: common.font-weight("base");
  }
}
