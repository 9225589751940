@use "../../../sass/utilities" as common;

$_border-color: common.color("grey", "light-1");
$_padding: common.spacing("layout", "minus-2") common.spacing("layout", "base");
$_header-background: common.color("grey", "light-2");

.ds-table {
  border-style: solid;
  border-width: 1px;
  border-color: $_border-color;
  width: 100%;
  max-width: 100%;
  margin: common.spacing("layout", "plus-3") 0;
  border-collapse: collapse;
  border-spacing: 0;

  th {
    font-weight: common.font-weight("semibold");
    color: common.color("charcoal", "dark-1");
    background-color: $_header-background;
    text-align: left;
  }

  td {
    padding: $_padding;
  }

  :is(thead, tbody, tfoot) {
    tr {
      :is(th, td) {
        padding: $_padding;
        line-height: common.line-height("large");
        vertical-align: top;
        border-style: solid;
        border-width: 1px;
        border-color: $_border-color;
      }
    }
  }

  caption {
    margin-top: common.spacing("type", "base");
    margin-bottom: common.spacing("type", "base");
    @include common.font-style("label", "medium");
    text-align: left;
  }
}

.ds-table:not(.ds-attention, .ds-success, .ds-warning, .ds-error) {
  border-top-width: 2px;
  border-top-color: common.color("primary", "base");
}

.ds-table {
  &.ds-attention {
    border-left: 5px solid common.color("attention", "dark-1");
    th {
      background-color: common.color("attention", "light-3");
    }
  }

  &.ds-success {
    border-left: 5px solid common.color("success", "dark-1");
    th {
      background-color: common.color("success", "light-3");
    }
  }

  &.ds-warning {
    border-left: 5px solid common.color("warning", "dark-1");
    th {
      background-color: common.color("warning", "light-3");
    }
  }

  &.ds-error {
    border-left: 5px solid common.color("error", "dark-1");
    th {
      background-color: common.color("error", "light-3");
    }
  }
}

.ds-table.ds-striped {
  tbody {
    tr {
      &:nth-child(2n) {
        background-color: common.color("grey", "light-2");
      }
    }
  }
}

.ds-responsive-table {
  overflow-x: auto;
  min-height: 0.01%;
  margin: common.spacing("layout", "plus-3") auto;
  padding-block-end: common.spacing("layout", "minus-3");

  .ds-table {
    margin: 0;
    max-width: unset;
    width: max-content;
  }
}
