@use "sass:math";
@use "../../../../sass/utilities" as common;

$_checkbox-size: 16px;
$_checkbox-radius: common.border-radius(2);
$_icon-size: 13px;
$_icon-spacing: 4px;

// Basic layout

.ds-form-checkbox {
  line-height: common.line-height("small");
  margin: common.spacing("type", "minus-1") 0;
  position: relative;
}

// Label
.ds-form-checkbox {
  .ds-form-label {
    font-weight: common.font-weight("base");
    padding-left: common.spacing("layout", "plus-3");
    width: auto;
    height: 100%;
    display: inline-block;
    color: common.color("charcoal");
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: color 150ms ease-in;
    margin: 0;

    &::before {
      width: 0;
      height: 0;
      border-radius: common.border-radius("max");
      content: "";
      background-color: common.color("grey", "light-1");
      position: absolute;
      left: math.div($_checkbox-size, 2);
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

    &::after {
      width: $_checkbox-size;
      height: $_checkbox-size;
      content: "";
      border: 2px solid common.color("charcoal", "light-2");
      background-repeat: no-repeat;
      background-position: center;
      border-radius: $_checkbox-radius;
      z-index: 2;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }

    &:hover {
      &::before {
        width: $_checkbox-size * 1.75;
        height: $_checkbox-size * 1.75;
        opacity: 1;
      }

      &::after {
        border: 2px solid common.color("charcoal", "light-1");
      }
    }

    &:active {
      &::after {
        background-color: common.color("primary", "dark-2");
        border-color: common.color("primary", "dark-2");
      }
    }
  }

  input:is(:checked, :indeterminate) {
    ~ .ds-form-label {
      &::before {
        opacity: 1;
      }

      &::after {
        background-color: common.color("primary");
        border-color: common.color("primary");
        background-image: common.get-icon(
          "fa-solid-minus",
          common.color("white")
        );
      }

      &:active {
        &::after {
          background-color: common.color("primary", "dark-2");
          border-color: common.color("primary", "dark-2");
        }
      }
    }

    &:active {
      ~ .ds-form-label {
        &::after {
          background-color: common.color("primary", "dark-2");
          border-color: common.color("primary", "dark-2");
        }
      }
    }
  }

  input:is(:checked) {
    ~ .ds-form-label {
      &::after {
        background-image: common.get-icon(
          "fa-solid-check",
          common.color("white")
        );
      }
    }
  }

  input:disabled {
    cursor: not-allowed;

    ~ .ds-form-label {
      cursor: not-allowed;
      color: common.color("grey");

      &::before {
        display: none;
      }

      &::after {
        border-color: common.color("grey", "dark-1");
        background-color: common.color("grey", "light-2");
        cursor: not-allowed;
      }

      &:active {
        ~ .ds-form-label::after {
          border-color: common.color("grey", "dark-1");
          background-color: common.color("grey", "light-2");
        }
      }
    }

    &:active {
      &::after {
        border-color: common.color("grey", "dark-1");
        background-color: common.color("grey", "light-2");
      }
    }

    &:is(:checked, :indeterminate) {
      ~ .ds-form-label::after {
        background-image: common.get-icon(
          "fa-solid-minus",
          common.color("grey", "dark-1")
        );
      }

      &:active {
        &::after {
          border-color: common.color("grey", "dark-1");
          background-color: common.color("grey", "light-2");
        }
      }
    }

    &:is(:checked) {
      ~ .ds-form-label {
        &::after {
          background-color: common.color("grey");
          border-color: common.color("grey");
          background-image: common.get-icon(
            "fa-solid-check",
            common.color("charcoal")
          );
        }
      }
    }
  }

  input {
    width: $_checkbox-size;
    height: $_checkbox-size;
    order: 1;
    z-index: 2;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    appearance: none;
    pointer-events: none;

    &:checked {
      &::after {
        background-color: common.color("primary");
        border-color: common.color("primary");
        background-image: common.get-icon(
          "fa-solid-check",
          common.color("white")
        );
      }

      &:active {
        ~ .ds-form-label {
          &::after {
            border-color: common.color("grey", "dark-1");
            background-color: common.color("grey", "light-2");
          }
        }
      }
    }

    &:focus {
      outline: 3px solid common.color("blue", "light-2");
      outline-offset: 2px;
    }
  }
}

// Supportive text
.ds-form-checkbox-group {
  border: none;

  .ds-form-supportive-text {
    display: block;
    color: common.color("charcoal", "light-2");
    margin: common.spacing("layout", "minus-1") 0
      common.spacing("layout", "minus-3");
    @include common.font-style("label", "medium");
    font-weight: common.font-weight("base");
  }
}

// Validation Errors
.ds-form-checkbox-group {
  &.ds-field-invalid {
    .ds-form-supportive-text {
      &.ds-purpose-error {
        color: common.color("error", "dark-1");
        padding-left: common.spacing("layout", "plus-1");

        background-repeat: no-repeat;
        background-size: $_icon-size $_icon-size;
        background-position: left 3px;
        background-image: common.get-icon(
          "fa-solid-triangle-exclamation",
          common.color("error", "dark-1")
        );
      }
    }
  }
}
