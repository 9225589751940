@use "../../../../sass/utilities" as common;

figure {
  // Set intial element height to match content to follow
  padding-top: common.spacing("layout", "minus-3");

  display: initial;

  // Override user agent rules
  margin-block-start: auto;
  margin-block-end: auto;
  margin-inline-start: auto;
  margin-inline-end: auto;

  // Caption styles
  figcaption {
    margin: common.spacing("type", "plus-1") 0;
    @include common.font-style("label", "medium");
  }

  // Image styles
  img {
    width: 100%;
    height: auto;
  }
}
