@use "../../../../sass/utilities" as common;

:is(ul, ol) {
  margin: common.spacing("type", "plus-2") 0;
  padding: 0 0 0 common.spacing("type", "plus-1");

  li {
    line-height: common.line-height("large");
    margin-top: common.spacing("type", "minus-1");
    margin-bottom: common.spacing("type", "minus-1");

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  :is(ul, ol) {
    margin-top: common.spacing("type", "plus-2");
    margin-bottom: common.spacing("type", "plus-2");
  }

  &:first-child {
    margin-top: common.spacing("type", "base");
  }

  &:last-child {
    margin-bottom: common.spacing("type", "base");
  }

  &.ds-list-unstyled {
    padding-left: 0;
    list-style: none;
  }

  &.ds-list-inline {
    padding-left: 0;

    li {
      display: inline-block;
      margin-right: common.spacing("type", "base");

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
