@use "../../functions" as *;

@mixin a11y-sr-only {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px 1px 1px 1px); // IE 6 and 7
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  overflow: hidden !important;
}

@mixin a11y-skip-link {
  @include a11y-sr-only;
  background-color: color("charcoal", "light-2");
  color: color("white") !important;

  &:focus {
    top: spacing("layout", "base");
    left: spacing("layout", "base");
    width: auto;
    height: auto;
    clip: unset;
    clip-path: unset;

    z-index: 9999;
  }
}
