@use "sass:list";
@use "sass:map";
@use "sass:meta";
@use "../../functions/font-size/font-size" as *;
@use "../../functions/line-height/line-height" as *;

@function map-deep-get($map, $keys...) {
  $scope: $map;
  $i: 1;
  @while (meta.type-of($scope) == map) and ($i <= list.length($keys)) {
    $scope: map.get($scope, list.nth($keys, $i));
    $i: $i + 1;
  }
  @return $scope;
}

$_font-style: (
  "heading": (
    "hero": (
      "font": font-size("plus-7"),
      "line": line-height("small"),
    ),
    "h1": (
      "font": font-size("plus-6"),
      "line": line-height("small"),
    ),
    "h1--alt": (
      "font": font-size("plus-5"),
      "line": line-height("small"),
    ),
    "h2": (
      "font": font-size("plus-4"),
      "line": line-height("small"),
    ),
    "h3": (
      "font": font-size("plus-3"),
      "line": line-height("small"),
    ),
    "h4": (
      "font": font-size("plus-2"),
      "line": line-height("small"),
    ),
    "h5": (
      "font": font-size("plus-1"),
      "line": line-height("small"),
    ),
    "h6": (
      "font": font-size("base"),
      "line": line-height("small"),
    ),
  ),
  "body": (
    "base": (
      "font": font-size("base"),
      "line": line-height("large"),
    ),
    "footnote": (
      "font": font-size("minus-1"),
      "line": line-height("large"),
    ),
  ),
  "label": (
    "large": (
      "font": font-size("ui"),
      "line": line-height("small"),
    ),
    "medium": (
      "font": font-size("minus-1"),
      "line": line-height("small"),
    ),
    "small": (
      "font": font-size("minus-2"),
      "line": line-height("small"),
    ),
  ),
);

@mixin font-style($name, $variant) {
  $font-map: map.get($_font-style, $name);

  @if $font-map == null {
    @warn "Font style `#{$name}` is not defined. Please use one of the following: #{map.keys($_font-style)}";
  } @else if not map.has-key($font-map, $variant) {
    $valid-variants: map.keys($font-map);

    @warn "Font style variant `#{$variant}` for `#{$name}` is not defined. Please use one of the following: #{$valid-variants}";
  } @else {
    $font-size: map-deep-get($_font-style, $name, $variant, "font");
    $line-height: map-deep-get($_font-style, $name, $variant, "line");

    @if $font-size == null or $line-height == null {
      @warn "Font style values for `#{$name}` variant `#{$variant}` do not match the map.";
    }

    font-size: $font-size;
    line-height: $line-height;
  }
}
