@use "../../../../sass/utilities" as common;

dl {
  margin: common.spacing("type", "plus-2") 0;
  padding: 0;

  dt {
    line-height: common.line-height("large");
    margin-bottom: common.spacing("type", "minus-1");

    &:last-child {
      margin-bottom: 0;
    }
  }

  dd {
    line-height: common.line-height("large");
    margin-top: common.spacing("type", "minus-1");
    margin-bottom: common.spacing("type", "minus-1");

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  dl:first-child {
    margin-top: common.spacing("type", "minus-1");
    margin-bottom: 0;
  }
}
