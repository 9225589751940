@use "../../../../sass/utilities" as common;
@use "../../../../sass/variables/form-settings" as form;

// Basic layout
.ds-form-item-select {
  margin: 24px 0;
}

// Dimensions and typography
.ds-form-select {
  appearance: none;
  display: block;
  width: 100%;
  height: form.$form-input-min-height;
  background-color: common.color("white");
  border: 1px solid common.color("grey", "base");
  border-radius: common.border-radius(4);
  padding: 0 common.spacing("layout", "base") * 3 0
    common.spacing("layout", "base");
  margin: common.spacing("type", "minus-2") 0;
  font-family: inherit;
  @include common.font-style("heading", "h6");
  font-weight: common.font-weight("base");

  &:hover {
    border-color: common.color("charcoal", "light-1");
  }

  &:focus {
    outline: 3px solid common.color("blue", "light-2");
    outline-offset: 2px;
  }
}

// Supportive text
.ds-form-select {
  & + .ds-form-supportive-text {
    color: common.color("charcoal", "light-2");
    margin: common.spacing("type", "minus-2") 0;
    @include common.font-style("label", "medium");
  }

  &[data-touched="true"]:invalid,
  &.ng-touched.ng-invalid {
    & + .ds-form-supportive-text {
      color: common.color("error", "dark-2");
    }
  }
}

// Validation
.ds-form-select {
  &[data-touched="true"]:invalid,
  &.ng-touched.ng-invalid {
    border: 2px solid common.color("error", "base");
    &:hover {
      border-color: common.color("error", "dark-2");
    }
  }
}

// Icons
.ds-form-select {
  background: common.get-icon(
      "fa-solid-caret-down",
      common.color("charcoal", "light-2")
    )
    no-repeat right 16px center;
  background-size: 16px 16px;

  &[data-touched="true"]:invalid,
  &.ng-touched.ng-invalid {
    background: common.get-icon(
          "fa-solid-caret-down",
          common.color("charcoal", "light-2")
        )
        no-repeat right 16px center,
      common.get-icon(
          "fa-solid-circle-exclamation",
          common.color("error", "dark-2")
        )
        no-repeat right 48px center;
    background-size: 16px 16px;
  }

  &[data-dirty="true"]:valid,
  &.ng-touched.ng-valid {
    background: common.get-icon(
          "fa-solid-caret-down",
          common.color("charcoal", "light-2")
        )
        no-repeat right 16px center,
      common.get-icon(
          "fa-solid-circle-check",
          common.color("success", "dark-2")
        )
        no-repeat right 48px center;
    background-size: 16px 16px;
  }
}

// Disabled and read-only state
.ds-form-select {
  &[disabled] {
    border-color: common.color("grey", "base");
    background-color: common.color("grey", "light-2");
    color: common.color("grey", "base");
    cursor: not-allowed;

    & + .ds-form-supportive-text {
      color: common.color("grey", "base");
    }
  }
}
