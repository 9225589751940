@use "../../shared";
@use "../../../../sass/utilities" as common;

pre:not([class]) {
  width: 100%;
  margin: common.spacing("layout", "base") auto;
  padding: common.spacing("layout", "base");

  @include shared.code-like;

  border: 1px solid common.color("grey", "light-1");
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  box-sizing: border-box;
  overflow: auto;
  overflow-y: hidden;
}
