@use "../../../../sass/utilities" as common;

.ds-button-group {
  display: flex;
  flex-wrap: wrap;
  &,
  &.ds-layout-align-left {
    justify-content: flex-start;
  }
  &.ds-layout-align-right {
    justify-content: flex-end;
  }
  &.ds-layout-align-center {
    justify-content: center;
  }
  &.ds-layout-stretch {
    .ds-button {
      flex: 1 0 auto;
    }
  }
  &.ds-layout-stack {
    .ds-button {
      flex: 0 0 100%;
    }
  }
}

.ds-button-group {
  &,
  &.ds-size-small {
    gap: common.spacing("layout", "minus-2");
  }
  &.ds-size-large {
    gap: common.spacing("layout", "minus-1");
  }
}
