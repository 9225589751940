@use "../../../../sass/utilities" as common;

a {
  font-weight: common.font-weight("semibold");
  color: common.color("anchor", "idle");

  &:visited {
    color: common.color("anchor", "visited");
  }
  &:focus {
    color: common.color("anchor", "idle");
  }
  &:hover {
    color: common.color("anchor", "hover");
  }
  &:active {
    color: common.color("anchor", "press");
  }
}

:-moz-focusring {
  outline: 2px solid #00a1ff;
}
