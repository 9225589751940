@mixin shadow-dom-reset {
  box-sizing: border-box;
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  *:first-child {
    margin-top: 0;
  }
  *:last-child {
    margin-bottom: 0;
  }
}
