@use "../../../sass/utilities" as common;

$_font-family: "Open Sans", Arial, Helvetica, sans-serif;

html {
  @include common.font-style("body", "base");
  -webkit-font-smoothing: antialiased;
  background-color: common.color("white");
  overflow-wrap: break-word;
  word-break: break-word;
}

body {
  font-family: $_font-family;
  font-weight: common.font-weight("base");
  background-color: common.color("white");
  color: common.color("text", "default");
}

input,
textarea {
  font-family: $_font-family;
}
