@use "../../../../sass/utilities" as common;

.ds-align {
  &-left {
    text-align: left;
    margin-top: common.spacing("layout", "plus-1");
    margin-bottom: common.spacing("layout", "plus-2");
  }
  &-right {
    text-align: right;
    margin-top: common.spacing("layout", "plus-1");
    margin-bottom: common.spacing("layout", "plus-2");
  }
  &-center {
    text-align: center;
    margin-top: common.spacing("layout", "plus-1");
    margin-bottom: common.spacing("layout", "plus-2");
  }
  &-justify {
    text-align: justify;
    margin-top: common.spacing("layout", "plus-1");
    margin-bottom: common.spacing("layout", "plus-2");
  }
}

.ds-float {
  &-left {
    float: left;
    margin: 0 common.spacing("layout", "base") common.spacing("layout", "base")
      0;
  }
  &-right {
    float: right;
    margin: 0 0 common.spacing("layout", "base")
      common.spacing("layout", "base");
  }
}
