*:first-child {
  margin-top: 0;
}

*:last-child {
  margin-bottom: 0;
}

*:is(ul, ol):first-child {
  margin-top: 1em;
}

*:is(ul, ol):last-child {
  margin-bottom: 1em;
}
