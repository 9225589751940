@use "../../../sass/utilities" as common;

$_border-width: 1px;
$_border-style: solid;
$_border-color: common.color("grey", "light-1");

.ds-border {
  border: $_border-width $_border-style $_border-color;
}

.ds-border {
  &-top {
    border-top: $_border-width $_border-style $_border-color;
  }

  &-right {
    border-right: $_border-width $_border-style $_border-color;
  }

  &-bottom {
    border-bottom: $_border-width $_border-style $_border-color;
  }

  &-left {
    border-left: $_border-width $_border-style $_border-color;
  }
}
