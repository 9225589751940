@use "sass:map";
@use "sass:math";
@use "../../../../sass/utilities" as common;
@use "../../../variables/button-geometry" as geometry;

/*
Base styles common to all permutations
*/
.ds-button {
  font-family: inherit;
  font-weight: common.font-weight("bold");
  text-decoration: none;
  text-shadow: none;
  text-align: center;
  text-transform: none;
  cursor: pointer;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block; // Resolve bleeding elements
  border-width: 1px;
  border-style: solid;

  &:focus {
    outline: 3px solid common.color("blue", "light-2");
    outline-offset: 2px;
  }
}

/*
Set colours based on the format option
*/
.ds-button {
  &,
  &.ds-format-solid {
    color: common.color("text", "inverse");
    background-color: common.color("primary", "base");
    border-color: common.color("primary", "base");
    &:hover {
      background-color: common.color("primary", "dark-1");
      border-color: common.color("primary", "dark-1");
    }
    &:active {
      background-color: common.color("primary", "dark-2");
      border-color: common.color("primary", "dark-2");
    }
  }

  &.ds-format-outline {
    color: common.color("primary", "dark-1");
    background-color: common.color("white", "base");
    border-color: common.color("grey", "light-1");

    &:hover {
      color: common.color("primary", "dark-1");
      background-color: common.color("primary", "light-3");
      border-color: common.color("grey", "base");
    }

    &:active {
      color: common.color("primary", "dark-2");
      background-color: common.color("primary", "light-2");
      border-color: common.color("primary", "light-2");
    }
  }

  &.ds-format-text {
    color: common.color("primary", "dark-1");
    background-color: transparent;
    border-color: transparent;
    &:hover {
      color: common.color("primary", "dark-1");
      background-color: transparent;
      border-color: transparent;
      text-decoration: underline;
    }
    &:active {
      color: common.color("primary", "dark-2");
      background-color: transparent;
      border-color: transparent;
      text-decoration: underline;
    }
  }
  &.ds-format-inverse {
    color: common.color("text", "inverse");
    background-color: transparent;
    border-color: transparent;

    &:hover {
      text-decoration: underline;
    }
  }
  &.ds-format-error {
    color: common.color("text", "inverse");
    background-color: common.color("error", "base");
    border-color: common.color("error", "base");
    &:hover {
      background-color: common.color("error", "dark-1");
      border-color: common.color("error", "dark-1");
    }
    &:active {
      background-color: common.color("error", "dark-2");
      border-color: common.color("error", "dark-2");
    }
  }
}

/*
Set the button size
*/
.ds-button {
  &,
  &.ds-size-small {
    font-size: map.get(geometry.$small, font-size);
    line-height: map.get(geometry.$small, line-height);
    height: map.get(geometry.$small, button-height);
    padding-top: math.div(
      map.get(geometry.$small, button-height) -
        map.get(geometry.$small, font-size),
      2
    );
    padding-bottom: math.div(
      map.get(geometry.$small, button-height) -
        map.get(geometry.$small, font-size),
      2
    );
  }
  &.ds-size-large {
    font-size: map.get(geometry.$large, font-size);
    line-height: map.get(geometry.$large, line-height);
    height: map.get(geometry.$large, button-height);
    padding-top: math.div(
      map.get(geometry.$large, button-height) -
        map.get(geometry.$large, font-size),
      2
    );
    padding-bottom: math.div(
      map.get(geometry.$large, button-height) -
        map.get(geometry.$large, font-size),
      2
    );
  }
}

/*
Set the button end shapes
*/
.ds-button {
  border-radius: 999px;
  min-width: 92px;
}

/*
Set the L/R padding and minumum width. Dependent on the size and shape options
*/
.ds-button {
  &,
  &.ds-size-small {
    padding-left: map.get(geometry.$small, round-end-padding);
    padding-right: map.get(geometry.$small, round-end-padding);
    min-width: map.get(geometry.$small, min-width);

    &.ds-type-text {
      min-width: map.get(geometry.$small, inner-min-width);
    }
  }
  &.ds-size-large {
    padding-left: map.get(geometry.$large, round-end-padding);
    padding-right: map.get(geometry.$large, round-end-padding);
    min-width: map.get(geometry.$large, min-width);

    &.ds-type-text {
      min-width: map.get(geometry.$large, inner-min-width);
    }
  }
}

// Full width
.ds-button {
  &.ds-full-width {
    width: 100%;
  }
}

.ds-button {
  &.ds-button-icon {
    width: 40px;
    min-width: unset;
    padding-left: 0;
    padding-right: 0;
  }
}

// Disabled state overrides
.ds-button {
  &[disabled],
  &[disabled]:hover,
  &[disabled]:active,
  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    cursor: not-allowed;
    color: common.color("white");
    background-color: common.color("grey", "dark-1");
    border-color: common.color("grey", "dark-1");
    text-decoration: none;

    &.ds-format-outline {
      color: common.color("grey", "light-1");
      background-color: common.color("white");
      border-color: common.color("grey", "light-1");
    }

    &.ds-format-text {
      color: common.color("grey", "light-1");
      background-color: transparent;
      border-color: transparent;
    }
  }
}
