@use "sass:map";
@use "sass:math";
@use "../../../../sass/utilities" as common;
@use "../../../variables/button-geometry" as geometry;

.ds-cta {
  font-family: inherit;
  font-weight: common.font-weight("bold");
  text-decoration: none;
  text-shadow: none;
  text-align: center;
  text-transform: none;
  cursor: pointer;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block; // Resolve bleeding elements
  border-width: 1px;
  border-style: solid;
  border-radius: common.border-radius("max");
  min-width: 92px;
  &:focus {
    outline: 3px solid common.color("blue", "light-2");
    outline-offset: 2px;
  }
}

/*
Set colours based on the format option
*/
.ds-cta {
  &,
  &.ds-format-solid {
    &:link,
    &:visited {
      color: common.color("text", "inverse");
      background-color: common.color("primary", "base");
      border-color: common.color("primary", "base");
    }
    &:hover {
      color: common.color("text", "inverse");
      background-color: common.color("primary", "dark-1");
      border-color: common.color("primary", "dark-1");
    }
    &:active {
      color: common.color("text", "inverse");
      background-color: common.color("primary", "dark-2");
      border-color: common.color("primary", "dark-2");
    }
  }
  &.ds-format-outline {
    &:link,
    &:visited {
      color: common.color("primary", "dark-1");
      background-color: common.color("white", "base");
      border-color: common.color("grey", "light-1");
    }
    &:hover {
      color: common.color("text", "inverse");
      background-color: common.color("primary", "dark-1");
      border-color: common.color("primary", "dark-1");
    }
    &:active {
      color: common.color("text", "inverse");
      background-color: common.color("primary", "dark-2");
      border-color: common.color("primary", "dark-2");
    }
  }
}

/*
Set the button size
*/
.ds-cta {
  &,
  &.ds-size-small {
    font-size: map.get(geometry.$small, font-size);
    line-height: map.get(geometry.$small, line-height);
    height: map.get(geometry.$small, button-height);
    padding-top: math.div(
      map.get(geometry.$small, button-height) -
        map.get(geometry.$small, font-size),
      2
    );
    padding-bottom: math.div(
      map.get(geometry.$small, button-height) -
        map.get(geometry.$small, font-size),
      2
    );
    padding-left: map.get(geometry.$small, round-end-padding);
    padding-right: map.get(geometry.$small, round-end-padding);
  }
  &.ds-size-large {
    font-size: map.get(geometry.$large, font-size);
    line-height: map.get(geometry.$large, line-height);
    height: map.get(geometry.$large, button-height);
    padding-top: math.div(
      map.get(geometry.$large, button-height) -
        map.get(geometry.$large, font-size),
      2
    );
    padding-bottom: math.div(
      map.get(geometry.$large, button-height) -
        map.get(geometry.$large, font-size),
      2
    );
    padding-left: map.get(geometry.$large, round-end-padding);
    padding-right: map.get(geometry.$large, round-end-padding);
  }
}

/*
Set the L/R padding and minumum width. Dependent on the size and shape options
*/
.ds-cta {
  &,
  &.ds-size-small {
    padding-left: map.get(geometry.$small, round-end-padding);
    padding-right: map.get(geometry.$small, round-end-padding);
    min-width: map.get(geometry.$small, min-width);
  }
  &.ds-size-large {
    padding-left: map.get(geometry.$large, round-end-padding);
    padding-right: map.get(geometry.$large, round-end-padding);
    min-width: map.get(geometry.$large, min-width);
  }
}
