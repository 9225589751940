@use "../../../sass/utilities" as common;

.ds-fieldset {
  border: none;
  margin: common.spacing("layout", "base") 0;
  padding: 0;

  .ds-form-field {
    &:first-child {
      margin-block-start: 0;
    }

    &:last-child {
      margin-block-end: 0;
    }
  }
}

// Wrapper for input, label and halper text
.ds-form-field {
  margin: common.spacing("layout", "plus-2") 0;
  padding: 0;
}

.ds-form-label {
  display: inline-block;
  margin: 0;
  @include common.font-style("heading", "h6");
  font-weight: common.font-weight("semibold");
}

.ds-legend {
  margin: 0;
  padding: 0;
  @include common.font-style("heading", "h4");
  font-weight: common.font-weight("bold");

  // Provide for labels required as part of legend pattern
  .ds-form-label {
    @include common.font-style("heading", "h6");
    font-weight: common.font-weight("semibold");
  }

  // Provide for supportive text required as part of legend pattern
  .ds-form-supportive-text {
    font-weight: common.font-weight("base");
    margin-bottom: 0;
  }
}
